<template>

	<div>
		<section class="header-bg" style="min-height: 255px;">
		
		</section>
		<section class="container-1200" style="margin-top: 40px;">
			<div style="height: 20px;">
				<p class="title-hr"><strong style="font-size: 28px;font-family: 'Bebas Neue', cursive;">Widget</strong>
				</p>
			</div>
			<div style="margin-top: 80px;text-align: center;">
				<p class="layui-font-green layui-font-20">No Data</p>
			</div>
		</section>
	</div>


</template>

<script>
	export default {
		name: "article",
		data() {
			return {
			};
		},
		watch: {},
		created() {

		},
		mounted() {},
		methods: {
			
		},
	}
</script>

<style></style>